import { useEffect, useReducer, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { serialize, type SWRResponse } from 'swr/_internal';
import useSWRInfinite from 'swr/infinite';
import type { DriveFile, DriveFileWithExport, ExportResult } from './DriveAPI';
import * as DriveAPI from './DriveAPI';
import {
  defaultScope,
  DEV,
  disableFocusRequest,
  expiredToken,
  isBun,
  mimeTypes,
  newFileId,
  preview,
} from './constants';
import { generateQuery } from './generateQuery';
import gup from './gup';
import { isMarkdownLink } from './isMarkdownLink';
import { log } from './logger';
import { recursiveFetch } from './middleware/recursiveFetch';
import { setToken } from './middleware/setToken';
import { throttle } from './middleware/throttle';
import { sortByNameFn } from './sort';
import type { Profile } from './types';
import {
  getId,
  getMimeType,
  getSharedDriveId,
  getTextFromFile,
  isADocument,
  isAFolder,
  isAPDF,
  isAShortcut,
  isExportable,
  isInputEvent,
  sortArrByName,
  uniqueBy,
} from './utils';

// The modern token refresh is handled by the server
export let useAuth = (): {
  anonymous: boolean | undefined;
  authorized: boolean;
  loaded: boolean;
  user: Profile | undefined;
} => {
  const { data: user, isLoading } = useSWR<Profile | null, unknown>(
    '/api/user/me',
    async (url: string) => {
      log.info('GET: /user/me');
      const response = await fetch(url, {
        priority: 'high',
      });
      if (!response.ok) return null;
      const user = await response.json<Profile | undefined>();
      log.info('user', user);
      if (!user) return null;
      user.tokens.expiresAt = Date.now() + user.tokens.expires;
      return user;
    },
    {
      use: [setToken],
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: (data) => (data && 'tokens' in data ? data.tokens.expiresAt - Date.now() : 0),
    }
  );

  if (user && 'tokens' in user) {
    const expired = Date.now() > (user.tokens.expiresAt ?? 0);
    return {
      loaded: !expired && !isLoading,
      anonymous: false,
      authorized: !expired,
      user,
    };
  }

  return {
    loaded: isBun || (user === null && !isLoading),
    anonymous: true,
    authorized: false,
    user: undefined,
  };
};

// The legacy token refresh is handled by the gapi client
if (import.meta.env.VITE_LEGACY) {
  useAuth = (): { anonymous: boolean; authorized: boolean; loaded: boolean; user: Profile | undefined } => {
    const unauthorized = { anonymous: Boolean(gup('p')), loaded: true, authorized: false, user: undefined };
    if (typeof gapi === 'undefined' || !gapi.auth2) return { ...unauthorized, loaded: false };
    const authInstance = gapi.auth2.getAuthInstance();
    if (!authInstance) return unauthorized;
    const currentUser = gapi.auth2.getAuthInstance().currentUser.get();
    const profile = currentUser.getBasicProfile();
    if (!profile) return unauthorized;
    const auth = currentUser.getAuthResponse();

    const user = {
      id: profile.getId(),
      name: profile.getName(),
      email: profile.getEmail(),
      image: profile.getImageUrl(),
      tokens: {
        accessToken: auth.access_token,
        expires: auth.expires_in,
        expiresAt: auth.expires_at,
        scope: auth.scope,
      },
    } as const;

    return {
      loaded: true,
      anonymous: false,
      authorized: Boolean(user.tokens.accessToken),
      user,
    };
  };
}

export const useFolder = () => {
  const { authorized } = useAuth();
  const query = `'root' in parents and mimeType = 'application/vnd.google-apps.folder' and name = 'YNAW'`;
  return useSWR<DriveFile | undefined, unknown>(
    authorized ? `/drive/v3/files?q=${query}` : null,
    async () => {
      if (!query) return Promise.reject(new Error('No query provided'));
      const folders = await DriveAPI.list({ query, fields: 'files(id, name)' });
      return folders.at(0);
    },
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export const useWikiList = (id: string | undefined) => {
  const { authorized } = useAuth();
  const query = `'${id}' in parents and (mimeType = '${mimeTypes.folder}' or mimeType = '${mimeTypes.shortcut}' and shortcutDetails.targetMimeType = '${mimeTypes.folder}')`;
  return useSWR<DriveFile[] | undefined, unknown>(
    authorized && id ? `/drive/v3/files?q=${query}` : null,
    async () => {
      if (!query) return Promise.reject(new Error('No query provided'));
      const files = await DriveAPI.list({ query });
      const folders = files.filter((o) => o.mimeType === mimeTypes.folder);
      const shortcuts = files.filter((o) => isAShortcut(o));
      const requests = shortcuts.map((o) => DriveAPI.get({ id: o.shortcutDetails.targetId }));
      const responses = await Promise.allSettled(requests);

      // If there's a 404 error, the shortcuts folder has been deleted, trash the shortcut
      // const hasError = responses.some((o) => o.status === 'rejected');
      // if (hasError) {
      //   const trashedIndex = responses.findIndex((o) => o.status === 'rejected');
      //   const trashedShortcut = shortcuts[trashedIndex];
      //   const removed = responses[trashedIndex];
      //   const reason = removed && 'reason' in removed ? (removed.reason as { status: number }) : undefined;
      //   if (trashedShortcut && reason?.status === 404) {
      //     void DriveAPI.trash({ id: trashedShortcut.id });
      //   }
      // }
      const wikis = responses.map((o) => ('value' in o ? o.value : undefined)).filter((o): o is DriveFile => !!o);
      return sortArrByName(folders.concat(wikis));
    },
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export const useWiki = (
  id: string | undefined,
  wikis: DriveFile[] | undefined,
  folderId: string | undefined
): SWRResponse<DriveFile, unknown, unknown> => {
  const { loaded } = useAuth();
  const result = useSWR<DriveFile, unknown>(
    loaded && id ? `/drive/v3/files/${id}` : null,
    () => {
      if (!id) return Promise.reject(new Error('No id provided'));
      return DriveAPI.get({ id });
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data } = result;
  const isAncestor =
    data &&
    data?.id !== id &&
    wikis &&
    !wikis.some((o) => o.id === id) &&
    data &&
    data.id !== folderId &&
    data.parents?.[0];
  const ancestor = useSWR<DriveFile, unknown>(
    isAncestor ? `/api/ancestor/${id}` : null,
    async () => {
      if (data && data?.id === id) return data;
      if (!data || !folderId || !wikis) return Promise.reject(new Error('Not all args provided'));
      if (!data.parents?.[0]) return Promise.reject(new Error('No parent available'));
      if (data.properties?.YNAW === 'folder') return data;
      const ancestor = await DriveAPI.ancestor({ id: data.parents[0], folderId, wikis, fields: '*' });
      return ancestor;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return isAncestor ? ancestor : result;
};

// const filesPath = '/api/files/';
/**
 * Recursively fetches all files in the wiki
 * Updates the swr cache once completed
 * /api/files is used because we are fetching all files in the wiki, which does not match the drive api
 * @param id
 * @param fileId - If provided, it will update the list of files when a change to the individual file is made
 */
export const useFiles = (id: string | undefined): { data: DriveFile[]; isLoading: boolean } => {
  const { loaded, user } = useAuth();
  const [streamed, setStreamed] = useState<DriveFile[]>([]);
  const idRef = useRef<string | undefined>(id);
  const { data = [], isLoading } = useSWR(
    loaded && id
      ? {
          key: `/api/files/${id}`,
          id,
          email: user?.email,
        }
      : null,
    (data: DriveFile[]) => {
      if (!id) throw new Error('No wikiId provided');
      if (id !== idRef.current) throw new Error('wikiId changed');
      setStreamed((prev) => [...prev, ...data]);
    },
    {
      use: [recursiveFetch],
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  useEffect(() => {
    idRef.current = id;
    setStreamed([]);
  }, [id]);

  const result = data.length ? data : streamed;

  return {
    data: result,
    isLoading,
  };
};

export const useFile = (id: string | undefined, files: DriveFile[]) => {
  const { loaded } = useAuth();
  const file = files.find((o) => o.id === id);
  const revalidate = file?.capabilities?.canEdit;

  const { data } = useSWR<DriveFile, unknown>(
    loaded && id && id !== newFileId ? `/drive/v3/files/${id}` : null,
    async () => {
      if (!id) return Promise.reject(new Error('No id provided'));
      const f = files.find((o) => o.id === id);
      if (f && Date.now() < new Date(f.createdTime).getTime() + 100000) {
        return f;
      }
      const file = await DriveAPI.get({ id });
      return file;
    },
    {
      use: [throttle],
      ...((disableFocusRequest || preview) && {
        revalidateOnFocus: false,
      }),
      // Disable revalidation for viewers
      ...(!revalidate && {
        focusThrottleInterval: 1000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }),
    }
  );

  // For public wikis that the user doesn't have direct permission to the parent
  // But it can be found in the list
  if (data && !data?.parents && file?.parents) {
    return {
      data: {
        ...data,
        parents: file.parents,
      },
    };
  }

  return {
    data: data ?? files.find((file) => file.id === id),
  };
};

export const useExport = (file: DriveFile | undefined) => {
  const { loaded } = useAuth();
  const id = file && isExportable(file) ? getId(file) : undefined;
  const mimeType = getMimeType(file);

  const swr = useSWR(
    loaded && id && id !== newFileId ? `/drive/v3/files/${id}/export` : null,
    async () => {
      if (!id) throw new Error('No id provided');
      if (!mimeType) throw new Error('No mimeType provided');
      return DriveAPI.exportFile({ id, mimeType });
    },
    {
      use: [throttle],
      onError: (error: Error) => {
        TrackJS.console.log('useExport error:', file?.id);
        TrackJS.track(error);
      },
      focusThrottleInterval: 1000,
      // Disable for testing
      ...((disableFocusRequest || preview || isAPDF(file)) && {
        revalidateOnFocus: false,
      }),
      // No need to revalidate for viewers
      ...(!file?.capabilities?.canEdit && {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }),
    }
  );

  return swr;
};

/**
 * Gets exports for all files provided
 */
export const useExports = (
  files: DriveFile[] | undefined
): { data: DriveAPI.DriveFileWithExport[]; isLoading: boolean } => {
  const { loaded } = useAuth();
  const { data: results, isLoading } = useSWRInfinite(
    (pageIndex) => {
      if (!loaded) return null;
      if (!files?.length) return null;
      const file = files[pageIndex];
      if (!file) return null;
      return `/drive/v3/files/${file.id}/export`;
    },
    async (args) => {
      const id = args.split('/').at(-2);
      if (!id) throw new Error('No id provided');
      const file = files?.find((o) => o.id === id);
      const mimeType = getMimeType(file);
      if (!mimeType) throw new Error('No mimeType provided');
      return DriveAPI.exportFile({ id, mimeType });
    },
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      initialSize: files?.length ?? 0,
      revalidateAll: true,
      parallel: true,
    }
  );
  if (!results) return { data: [], isLoading };

  const data =
    files?.map((file, i) => {
      const data = results[i];
      return {
        ...file,
        data: data ?? {
          html: '',
        },
      };
    }) ?? [];

  return {
    data,
    isLoading,
  };
};

export const useCachedExports = (files: DriveFile[]): DriveFileWithExport[] => {
  const { cache } = useSWRConfig();

  const data = files
    .filter(isExportable)
    .map((file) => {
      const id = getId(file);
      if (!id) {
        TrackJS.console.log(file);
        TrackJS.track('useCachedExports - file does not contain id');
        return {
          ...file,
          data: {
            html: '',
          },
        };
      }
      const path = serialize(`/drive/v3/files/${id}/export`)?.[0];
      const data = cache.get(path)?.data as ExportResult | undefined;

      return {
        ...file,
        data,
      };
    })
    // If the export returns an error we cache an empty string to prevent the request from being made again
    // Which is why we're checking for a result or empty string here
    .filter((o) => o.data?.html || o.data?.html === '') as DriveFileWithExport[];

  return data;
};

/**
 * Immediately sets a boolean value to true but debounces setting it to false by a specified delay.
 */
export function useLeadingDebounceTruthy(value: boolean, delay = 100): boolean {
  const [debouncedValue, setDebouncedValue] = useState<boolean>(value);

  useEffect(() => {
    if (value) {
      setDebouncedValue(true);
    } else {
      const handler = setTimeout(() => {
        setDebouncedValue(false);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
    return;
  }, [value, delay]);

  if (isBun) return value;
  return debouncedValue;
}

export function useLeadingDebounce<T>(value: T, delay = 100): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timerRef = useRef<Timer>(undefined);
  const firstValue = useRef<T>(value);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (value !== debouncedValue) {
      if (value === firstValue.current) {
        setDebouncedValue(value);
        return;
      }

      timerRef.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    }

    return () => clearTimeout(timerRef.current);
  }, [value, delay, debouncedValue]);

  return debouncedValue;
}

/**
 * Returns search results based on query
 * @param input - Search query
 * @param wiki - The wiki folder
 * @param files - All files in the wiki
 * @param exportLimit - Limit the number of export requests
 */
export const useSearch = (
  input: string,
  wiki: DriveFile,
  files: DriveFile[]
): { data: DriveFile[]; isLoading: boolean } => {
  const { user } = useAuth();
  const lowerInput = input.toLowerCase();
  const folders = [wiki].concat(files.filter(isAFolder));
  const query = input.length > 0 ? generateQuery({ files: folders, wiki, email: user?.email }) : undefined;
  const prefix = '/drive/v3/files?q=';
  const {
    data: results,
    isLoading: isLoadingResults,
    isValidating: isValidatingResults,
  } = useSWRInfinite(
    (pageIndex) => {
      if (!query) return null;
      const q = typeof query === 'string' ? query : query[pageIndex];
      if (!q) return null;
      return `${prefix}fullText contains '${lowerInput}' and ${typeof query === 'string' ? `(${q})` : `${q}`}`;
    },
    async (args) => {
      const query = args.replace(prefix, '');
      const driveId = getSharedDriveId(folders);
      const data = (await DriveAPI.search({
        q: query,
        fields: 'files(id,name)',
        ...(driveId && { driveId }),
      }).catch(() => [])) as { id: string }[];
      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateAll: true,
      initialSize: query ? (typeof query === 'string' ? 1 : query.length) : 0,
      parallel: true,
    }
  );
  const ids = results?.flat().map((o) => o.id) ?? [];
  const searchResults = files.filter((o) => ids.includes(o.id));

  // only export a file if it's in the search results
  // run a check against cached files as a bonus (may be disabled in the future)
  const { data: searchFiles, isLoading: isLoadingExports } = useExports(searchResults);
  const cachedFilesWithHTML = useCachedExports(files)
    .map((file) => ({
      ...file,
      content: getTextFromFile(file),
    }))
    .filter((file) => file.content.includes(lowerInput));
  const nameFiles = files.filter((file) => file.name.toLowerCase().includes(lowerInput));

  type SearchEntry = DriveFile | (DriveFile & { content: string }) | DriveAPI.DriveFileWithExport;

  const unsortedData =
    input.length === 0
      ? []
      : ([...cachedFilesWithHTML, ...searchFiles, ...nameFiles].map((o: SearchEntry) => {
          const markdown = isADocument(o) ? isMarkdownLink(o.name) : undefined;
          return {
            ...o,
            nameLower: markdown ? markdown.name.toLowerCase() : o.name.toLowerCase(),
            content: 'content' in o ? o.content : 'data' in o ? getTextFromFile(o) : '',
          };
        }) as (DriveFile & { content: string; nameLower: string })[]);

  const data = uniqueBy(unsortedData, 'id')
    .filter((o, i, arr) => {
      // We can safely filter markdown links with the same name as they will generate the same result
      const isMarkdown = isADocument(o) && isMarkdownLink(o.name);
      if (!isMarkdown) return true;
      const duplicateNameIndex = arr.findIndex((f) => f.name === o.name && f.id !== o.id);
      const hasDuplicateName = duplicateNameIndex !== -1;
      const isDuplicateName = hasDuplicateName && i > duplicateNameIndex;
      return !isDuplicateName;
    })
    .sort(sortByNameFn)
    .sort((a, b) => {
      if (a.nameLower.startsWith(lowerInput) && !b.nameLower.startsWith(lowerInput)) return -1;
      if (!a.nameLower.startsWith(lowerInput) && b.nameLower.startsWith(lowerInput)) return 1;
      if (a.nameLower.includes(lowerInput) && !b.nameLower.includes(lowerInput)) return -1;
      if (a.nameLower.includes(lowerInput) && a.content.includes(lowerInput) && !b.content.includes(lowerInput)) {
        return -1;
      }
      if (a.content.includes(lowerInput) && !b.nameLower.includes(lowerInput) && !b.content.includes(lowerInput))
        return -1;
      return 0;
    });

  const isLoadingValue = useLeadingDebounceTruthy(isLoadingResults || isLoadingExports || isValidatingResults, 200);
  const isLoading = input.length === 0 ? false : isLoadingValue;
  return {
    data,
    isLoading,
  };
};

// TODO: need to save user's preference
export const useTheme = () => 'light';

// https://github.com/jacobbuck/react-use-keypress/blob/main/src/index.js
export const useKeyPress = (
  keys: KeyboardEvent['code'] | KeyboardEvent['code'][],
  callback: ((e: KeyboardEvent) => void) | undefined
) => {
  const eventListenerRef = useRef<(e: KeyboardEvent) => void>(undefined);
  useEffect(() => {
    if (!callback) return;
    eventListenerRef.current = (event: KeyboardEvent) => {
      if (Array.isArray(keys) ? keys.includes(event.key) : keys === event.key) {
        callback?.(event);
      }
    };
  }, [keys, callback]);

  useEffect(() => {
    if (!eventListenerRef.current) return;
    const eventListener = (event: KeyboardEvent) => {
      if (isInputEvent(event)) return;
      eventListenerRef.current?.(event);
    };
    window.addEventListener('keydown', eventListener);
    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, []);
};

export const useIsVisible = <T extends React.RefObject<HTMLElement | null>>(
  ref: T,
  cb: () => void,
  deps: unknown[] = []
): void => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: intended
  useEffect(() => {
    const target = ref.current;
    if (!window.IntersectionObserver) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          cb();
          if (target) observer.unobserve(target);
        }
      });
    });
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, deps);
};

/**
 * This is a hook to allow testing invalidating the token while the user is browsing
 */
export const useSimulateExpiredToken = (delay = 3000) => {
  const { cache } = useSWRConfig();
  // Simulate expired token
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const timerRef = useRef<Timer>(undefined);

  useEffect(() => {
    log.info('simulating expired token in', delay / 1000, 'seconds');
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      log.info('simulating expired token');
      cache.set('/api/user/me', {
        data: {
          tokens: {
            accessToken: 'expired',
            expires: 0,
            expiresAt: 0,
          },
        },
      });
      gapi.client.setToken({ access_token: 'expired', expires_in: 0, scope: defaultScope });
      forceUpdate();
    }, delay);
  }, [cache, delay]);
};

if (DEV && expiredToken) {
  gapi.client.setToken({
    access_token: 'expired',
    expires_in: 0,
    scope: defaultScope,
  });
}

export const useBeforeUnload = (callback: () => void) => {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      callbackRef.current();
    };
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, []);
};

export const useLast = <T>(value: T, deps: unknown[] = []): T | undefined => {
  const [lastValue, setLastValue] = useState<T | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: intended
  useEffect(() => {
    setLastValue(value);
  }, deps);

  return lastValue;
};
