import { isBun } from './constants';
import gup from './gup';

const logLevel = {
  INFO: 0,
  DEBUG: 1,
  WARNING: 2,
  ERROR: 3,
} as const;

type LogLevel = (typeof logLevel)[keyof typeof logLevel];

class Logger {
  private logLevel: LogLevel;

  constructor(level: LogLevel = logLevel.DEBUG) {
    this.logLevel = level;
  }

  private log(level: LogLevel, ...args: unknown[]): void {
    switch (level) {
      case logLevel.DEBUG:
        console.log(...args);
        break;
      case logLevel.INFO:
        console.log(...args);
        break;
      case logLevel.WARNING:
        console.warn(...args);
        break;
      case logLevel.ERROR:
        console.error(...args);
        break;
      default:
        break;
    }
  }

  debug(...args: unknown[]): void {
    if (this.logLevel <= logLevel.DEBUG) {
      this.log(logLevel.DEBUG, ...args);
    }
  }

  info(...args: unknown[]): void {
    if (this.logLevel <= logLevel.INFO) {
      this.log(logLevel.INFO, ...args);
    }
  }

  warning(...args: unknown[]): void {
    if (this.logLevel <= logLevel.WARNING) {
      this.log(logLevel.WARNING, ...args);
    }
  }
  error(...args: unknown[]): void {
    if (this.logLevel <= logLevel.ERROR) {
      this.log(logLevel.ERROR, ...args);
    }
  }
}

// Note that vite replaces all log.* calls in development with console.* to preserve line numbers
export const log = new Logger(
  isBun ? logLevel.DEBUG : import.meta.env?.DEV ? (gup('debug') ? logLevel.DEBUG : logLevel.INFO) : logLevel.INFO
);
