import dedent from 'dedent';
import {
  baseURL,
  DEV,
  fileFields,
  isBun,
  LEGACY,
  listFields,
  mimeTypes,
  MODERN,
  unSupportedExportFormats,
} from './constants';
import gup from './gup';
import { log } from './logger';
import type { UnionToIntersection } from './types';
import {
  decodeHTML,
  getExportMimeType,
  getTextFromHTML,
  get as getValue,
  isFileScope,
  pick,
  sanitizeHTML,
  strip,
} from './utils';

declare global {
  interface Window {
    gapiLoaded: Promise<unknown> | undefined;
    gapi: typeof gapi;
  }
}

// type Revision = {
//   id: string;
//   lastModifyingUser: {
//     displayName: string;
//     photoLink: string;
//   };
//   modifiedTime: string;
// };

export type Token = {
  access_token: string;
  expires_at: number;
  expires_in: number;
  first_issued_at: number;
  id_token: string;
  idpId: 'google';
  login_hint: string;
  scope: string;
  session_state: {
    extraQueryParams: {
      authuser: string;
    };
  };
  token_type: 'Bearer';
};

// For the app to function we only needs these properties
// It is just simpler when setting and getting
export type PartialToken = {
  access_token: string;
  id_token?: string; // legacy needs id_token to send to server via headers, new codebase uses cookies
  expires_in: number;
  scope: string;
};

// If you make updates here be sure to update server/types.ts
// https://developers.google.com/drive/api/guides/ref-roles
// https://developers.google.com/drive/api/v3/reference/permissions
type PermissionRole = 'owner' | 'organizer' | 'fileOrganizer' | 'writer' | 'commenter' | 'reader';
export type Permission =
  | {
      id: 'anyoneWithLink' | 'anyone';
      role: PermissionRole;
      type: 'anyone';
    }
  | {
      id: string;
      role: PermissionRole;
      type: 'user' | 'group';
      emailAddress: string;
      displayName: string;
      photoLink: string;
      deleted?: boolean;
    }
  | {
      id: string;
      role: PermissionRole;
      type: 'domain';
      displayName: string;
    };

// If you make updates here be sure to update server/types.ts
// https://developers.google.com/drive/api/reference/rest/v3/files
export type BaseFile = {
  id: string;
  name: string;
  driveId?: string;
  explicitlyTrashed: boolean;
  iconLink?: string;
  parents?: [string]; // Parents is optional for anyoneWithLink files
  webViewLink: string;
  webContentLink: string;
  lastModifyingUser?: {
    displayName: string;
    photoLink?: string;
    // Unused
    // me: boolean;
    // permissionId: string;
    // emailAddress: string;
  };
  createdTime: string;
  modifiedTime: string;
  owners?: {
    displayName: string;
    photoLink?: string;
    // Unused
    // me: boolean;
    // permissionId: string;
    emailAddress: string;
  }[];
  ownedByMe: boolean;
  permissions?: Permission[];
  properties?: {
    animations?: 'true' | 'false';
    color?: string;
    fontColor?: string;
    highlightTextMenu?: 'true' | 'false';
    showPrintButton?: 'true' | 'false';
    index?: string | null;
    parentId?: string | null; // Used to check if index is valid
    YNAW?: 'folder';
    unicode?: string;
    logoId?: string;
    logoURL?: string;
    sheetBorder?: 'true' | 'false';
    showPageTitle?: 'true' | 'false';
    collapsedMenu?: 'true' | 'false';
    showViewButtons?: 'true' | 'false';
    userMenu?: 'true' | 'false';
    showCreateWikiButton?: 'true' | 'false';
  };
  capabilities?: {
    canAddChildren?: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canTrash?: boolean;
  };
  thumbnailLink?: string;
  imageMediaMetadata?: {
    width: number;
    height: number;
  };
  size: number;
};

type Document = BaseFile & {
  mimeType: 'application/vnd.google-apps.document';
};

// If you make updates here be sure to update server/types.ts
export type DriveFile =
  // Shared drive
  | (Omit<BaseFile, 'parents'> & {
      mimeType: 'application/vnd.google-apps.folder';
      driveId: string;
      parents?: [string]; // Parents is optional for shared drives
    })
  | Document // Allows type narrowing
  | (BaseFile & {
      mimeType:
        | 'application/vnd.google-apps.document'
        | 'application/vnd.google-apps.folder'
        | 'application/vnd.google-apps.drawing'
        | 'application/vnd.google-apps.spreadsheet'
        | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        | 'application/vnd.google-apps.presentation'
        | 'application/vnd.ms-powerpoint'
        | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        | 'application/vnd.google-apps.form'
        | 'video/mp4'
        | 'video/quicktime'
        | 'text/markdown'
        | 'text/x-markdown'
        | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        | 'application/pdf'
        | 'application/x-iwork-numbers-sffnumbers'
        | 'image/jpeg'
        | 'text/plain'
        | 'text/html'
        | 'image/png'
        | 'image/vnd.adobe.photoshop'
        | 'application/postscript'
        | 'image/svg+xml'
        | 'application/octet-stream'
        | 'application/json'
        | 'application/x-iwork-pages-sffpages'
        | 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
        | 'text/csv'
        | 'application/zip'
        | 'application/msword'
        | 'application/x-ms-shortcut';
    })
  // Shortcut
  | (BaseFile & {
      mimeType: 'application/vnd.google-apps.shortcut';
      shortcutDetails: {
        targetId: string;
        targetMimeType: Exclude<DriveFile['mimeType'], 'application/vnd.google-apps.shortcut'>;
        target?: DriveFile;
      };
    });

export type TreeFile = DriveFile extends { mimeType: 'application/vnd.google-apps.shortcut' }
  ? DriveFile & {
      children?: DriveFile[];
      sortedChildren?: DriveFile[];
      page?: DriveFile | undefined;
      shortcutDetails: {
        targetId: string;
        targetMimeType: Exclude<DriveFile['mimeType'], 'application/vnd.google-apps.shortcut'>;
        target?: DriveFile | undefined;
      };
    }
  : DriveFile & {
      children?: DriveFile[];
      sortedChildren?: DriveFile[];
      page?: DriveFile | undefined;
    };

// export type Properties = NonNullable<BaseFile['properties']>;
export type DriveMimeType = DriveFile['mimeType'];

export type Params = {
  fields?: string;
  supportsAllDrives: true;
  includeItemsFromAllDrives: true;
};

export type GapiPromise<T> = {
  then: (o: (o: T) => unknown) => T;
  getPromise: () => Promise<T>;
};

export type GapiResponse<T> = GapiPromise<{
  body: string;
  headers: {
    'cache-control': 'no-cache, no-store, max-age=0, must-revalidate';
    'content-type': 'application/json; charset=UTF-8';
    'content-encoding': 'gzip';
    'content-length': string;
    date: string;
    expires: string;
    pragma: 'no-cache';
    server: 'ESF';
    vary: 'Origin, X-Origin';
  };
  status: 200;
  statusText: null;
  result: T;
}>;

// export type GapiError =
//   | {
//       result: {
//         error: {
//           code: 401;
//           message: string;
//           errors?: {
//             domain: 'global';
//             message: 'Invalid Credentials';
//             reason: 'authError';
//             location: 'Authorization';
//             locationType: 'header';
//           }[];
//           details?: [
//             {
//               '@type': 'type.googleapis.com/google.rpc.ErrorInfo';
//               reason: 'CREDENTIALS_MISSING';
//               domain: 'googleapis.com';
//               metadata: {
//                 method: 'google.apps.docs.v1.DocumentsService.GetDocument';
//                 service: 'docs.googleapis.com';
//               };
//             }
//           ];
//           status: 'UNAUTHENTICATED';
//         };
//       };
//       body: string;
//       headers: {
//         'cache-control': 'private';
//         'content-encoding': 'gzip';
//         'content-length': string;
//         'content-type': 'application/json; charset=UTF-8';
//         date: string;
//         expires?: string;
//         server: 'ESF';
//         vary?: 'Origin, X-Origin, Referer';
//         'www-authenticate':
//           | 'Bearer realm="https://accounts.google.com/"'
//           | 'Bearer realm="https://accounts.google.com/", error="invalid_token"';
//       };
//       status: 401;
//       statusText: null;
//     }
//   | {
//       result: {
//         error: {
//           code: 404;
//           message: string;
//           errors: {
//             domain: 'global';
//             message: `File not found: ${string}.`;
//             reason: 'notFound';
//             location: 'fileId';
//             locationType: 'parameter';
//           }[];
//         };
//       };
//       body: string;
//       headers: {
//         'cache-control': 'private, max-age=0';
//         'content-encoding': 'gzip';
//         'content-length': string;
//         'content-type': 'application/json; charset=UTF-8';
//         date: string;
//         expires: string;
//         server: 'ESF';
//         vary: 'Origin, X-Origin';
//       };
//       status: 404;
//       statusText: null;
//     }
//   | {
//       result: false;
//       body: string;
//       status: 404;
//       statusText: null;
//       headers: {
//         'content-length': string;
//         'content-type': 'text/html';
//         date: string;
//         server: string;
//       };
//     };

// https://developers.google.com/drive/api/guides/ref-export-formats
export type ExportMimeType =
  | 'text/html'
  | 'text/plain'
  | 'text/csv'
  | 'text/markdown'
  | 'application/zip'
  | 'application/pdf'
  | 'application/rtf'
  | 'application/vnd.oasis.opendocument.text'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.oasis.opendocument.spreadsheet'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/vnd.oasis.opendocument.presentation'
  | 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
  | 'application/vnd.google-apps.script+json'
  | 'application/epub+zip'
  | 'text/tab-separated-values'
  | 'image/jpeg'
  | 'image/png'
  | 'image/svg+xml'
  | 'media';

export type Drive = {
  Create: {
    options: {
      path: 'https://www.googleapis.com/drive/v3/files';
      method: 'POST';
      body: {
        name: string;
        mimeType: DriveMimeType;
        parents: [string];
      };
      params?: Params;
    };
    response: GapiResponse<DriveFile>;
  };
  //   CreateWithData: {
  //     options: {
  //       path: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart';
  //       method: 'POST';
  //       body: FormData;
  //     };
  //     response: Promise<{ result: DriveFile }>;
  //   };
  //   Copy: {
  //     options: {
  //       path: `https://www.googleapis.com/drive/v3/files/${string}/copy`;
  //       method: 'POST';
  //       body: {
  //         name?: string;
  //         parents: [string];
  //       };
  //       params?: Params;
  //     };
  //     response: Promise<{ result: DriveFile }>;
  //   };
  Export: {
    options: {
      path: `https://www.googleapis.com/drive/v3/files/${string}/export`;
      params: Params & {
        mimeType: ExportMimeType;
      };
    };
    response: GapiResponse<{ body: string }>;
  };
  Get: {
    options: {
      path: `https://www.googleapis.com/drive/v3/files/${string}`;
      params?: Params & {
        alt?: 'media';
      };
    };
    response: GapiResponse<DriveFile>;
  };
  // GetMedia: {
  //   options: {
  //     path: `https://www.googleapis.com/drive/v3/files/${string}`;
  //     params?: Params & {
  //       alt: 'media';
  //     };
  //   };
  //   response: GapiResponse<DriveFile>;
  // };
  List: {
    options: {
      path: 'https://www.googleapis.com/drive/v3/files';
      params: Params & {
        q: string;
        orderBy?: 'folder,name_natural';
        pageSize?: number;
      };
    };
    response: GapiResponse<{ files: DriveFile[]; nextPageToken?: string }>;
  };
  Rename: {
    options: {
      path: `https://www.googleapis.com/drive/v3/files/${string}`;
      method: 'PATCH';
      body: {
        trashed?: boolean;
        name?: string;
      };
      params?: Params;
    };
    response: GapiPromise<{ result: DriveFile }>;
  };
  //   Revisions: {
  //     options: {
  //       path: `https://www.googleapis.com/drive/v3/files/${string}/revisions`;
  //       params: Params & {
  //         fields?: string;
  //         pageSize?: number;
  //       };
  //     };
  //     response: Promise<{ result: { revisions: Revision[] } }>;
  //   };
  Trash: {
    options: {
      path: `https://www.googleapis.com/drive/v3/files/${string}`;
      method: 'PATCH';
      body: {
        trashed: true;
      };
    };
    response: GapiPromise<{ result: DriveFile }>;
  };
  Update: {
    options: {
      path: `https://www.googleapis.com/drive/v3/files/${string}`;
      method: 'PATCH';
      params: Params & {
        appProperties?: Record<string, string>;
        name?: string;
        mimeType?: DriveMimeType;
        addParents?: string; // A comma-separated list of parent IDs to add
        removeParents?: string; // A comma-separated list of parent IDs to remove
        trashed?: boolean;
        properties?: Record<string, string>;
      };
    };
    response: Promise<{ result: DriveFile }>;
  };
};

type Permissions = {
  // Create: {
  //   options: {
  //     path: `https://www.googleapis.com/drive/v3/files/${string}/permissions`;
  //     method: 'POST';
  //     body: {
  //       type: 'user' | 'group' | 'domain' | 'anyone';
  //       role: 'owner' | 'organizer' | 'fileOrganizer' | 'writer' | 'commenter' | 'reader';
  //       emailAddress?: string;
  //       domain?: string;
  //       allowFileDiscovery?: boolean;
  //     };
  //     params?: Params & {
  //       sendNotificationEmail?: boolean;
  //     };
  //   };
  //   response: Promise<{ result: Permission }>;
  // };
  // Delete: {
  //   options: {
  //     path: `https://www.googleapis.com/drive/v3/files/${string}/permissions/${string}`;
  //     method: 'DELETE';
  //     params?: Params;
  //   };
  //   response: Promise<{ result: Permission }>;
  // };
  // Get: {
  //   options: {
  //     path: `https://www.googleapis.com/drive/v3/files/${string}/permissions/${string}`;
  //     params?: Params;
  //   };
  //   response: Promise<{ result: Permission }>;
  // };
  List: {
    options: {
      path: `https://www.googleapis.com/drive/v3/files/${string}/permissions`;
      method: 'GET';
      params?: Params;
    };
    response: GapiPromise<{ result: { permissions: Permission[] } }>;
  };
  // https://developers.google.com/drive/api/v3/reference/permissions/update
  // Update: {
  //   options: {
  //     path: `https://www.googleapis.com/drive/v3/files/${string}/permissions/${string}`;
  //     method: 'PATCH';
  //     body: {
  //       role: PermissionRole;
  //     };
  //     params?: Params & {
  //       transferOwnership?: boolean;
  //     };
  //   };
  //   response: Promise<{ result: Permission }>;
  // };
};

// type Docs = {
//   Get: {
//     options: { path: `https://docs.googleapis.com/v1/documents/${string}` };
//     response: Promise<{
//       result: Document;
//     }>;
//   };
//   BatchUpdate: {
//     options: {
//       path: `https://docs.googleapis.com/v1/documents/${string}:batchUpdate`;
//       method: 'POST';
//       body: {
//         requests: {
//           insertText: {
//             text: string;
//             location: {
//               index: number;
//             };
//           };
//         }[];
//       };
//     };
//     response: Promise<{
//       result: Document;
//     }>;
//   };
//   Create: {
//     options: {
//       path: 'https://docs.googleapis.com/v1/documents';
//       method: 'POST';
//       body: {
//         title: string;
//       };
//     };
//     response: Promise<{ result: Document }>;
//   };
// };

// type Drives = {
//   Get: {
//     options: {
//       path: `https://www.googleapis.com/drive/v3/drives/${string}`;
//       params?: Params;
//     };
//     response: Promise<{ result: { name: string } }>;
//   };
// };

// export type Options = Drive[keyof Drive]['options'] | Docs[keyof Docs]['options'] | Drives[keyof Drives]['options'];

// export type Response = Drive[keyof Drive]['response'] | Docs[keyof Docs]['response'] | Drives[keyof Drives]['response'];

declare global {
  const gapi: {
    auth?: {
      getToken: () => PartialToken | undefined;
      setToken: (options: PartialToken) => void;
    };
    auth2?: {
      getAuthInstance: () => {
        currentUser: {
          get: () => {
            getBasicProfile: () => {
              getId: () => string;
              getEmail: () => string;
              getName: () => string;
              getImageUrl: () => string;
            };
            getAuthResponse: () => Token;
          };
          listen: (o: (o: { getAuthResponse: () => PartialToken }) => void) => void;
        };
        isSignedIn: {
          get: () => boolean;
          listen: (o: (o: boolean) => void) => void;
        };
        signIn: () => Promise<void>;
        signOut: () => Promise<void>;
      };
    };
    client: {
      getToken: () => PartialToken | null;
      request: {
        // (options: Drive['Copy']['options']): Drive['Copy']['response'];
        (options: Drive['Create']['options']): Drive['Create']['response'];
        // (options: Drive['CreateWithData']['options']): Drive['CreateWithData']['response'];
        (options: Drive['Get']['options']): Drive['Get']['response'];
        (options: Drive['Rename']['options']): Drive['Rename']['response'];
        (options: Drive['Update']['options']): Drive['Update']['response'];
        (options: Drive['Trash']['options']): Drive['Trash']['response'];
        (options: Drive['List']['options']): Drive['List']['response'];
        (options: Drive['Export']['options']): Drive['Export']['response'];
        // (options: Drive['Revisions']['options']): Drive['Revisions']['response'];
        // (options: Permissions['Create']['options']): Permissions['Create']['response'];
        // (options: Permissions['Delete']['options']): Permissions['Delete']['response'];
        // (options: Permissions['Get']['options']): Permissions['Get']['response'];
        (options: Permissions['List']['options']): Permissions['List']['response'];
        // (options: Permissions['Update']['options']): Permissions['Update']['response'];
        // (options: Permissions[keyof Permissions]['options']): Permissions[keyof Permissions]['response'];
        // // Possible simplification
        // // (options: Drive[keyof Drive]['options']): Drive[keyof Drive]['response'];
        // Docs
        // (options: Docs['Get']['options']): Docs['Get']['response'];
        // (options: Docs['BatchUpdate']['options']): Docs['BatchUpdate']['response'];
        // (options: Docs['Create']['options']): Docs['Create']['response'];
        // Drives
        // (options: Drives['Get']['options']): Drives['Get']['response'];
      };
      setApiKey: (s: string) => void;
      setToken: (options: PartialToken) => void;
    };
    load: (s: string, callback: () => void) => void;
  };
}

export const permissions = {
  list: async ({
    id,
    fields = 'permissions(id,role,type,emailAddress,displayName,photoLink,deleted)',
  }: { id: string; fields?: string }): Promise<Permission[]> => {
    const request = gapi.client.request({
      path: `https://www.googleapis.com/drive/v3/files/${id}/permissions`,
      method: 'GET',
      params: {
        fields,
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
      },
    });

    const response = await request.getPromise();
    return response.result.permissions;
  },
};

export const get = async ({ id, fields = fileFields }: { id: string; fields?: string }) => {
  const token = gapi.client.getToken();
  if (token && isFileScope(token.scope)) {
    // todo: write a request function than handles headers and .json()
    const res = await fetch(
      `${baseURL}/drive/v3/files/${id}?fields=${fields}`,
      LEGACY
        ? {
            headers: {
              token: JSON.stringify(pick(token, 'access_token', 'id_token')),
            },
          }
        : {
            credentials: 'include',
          }
    );
    if (!res.ok) {
      throw new Error(`Failed to fetch file: ${res.statusText}`);
    }
    const response = await res.json<{ result: DriveFile }>();
    return response.result;
  }

  const request = gapi.client.request({
    path: `https://www.googleapis.com/drive/v3/files/${id}`,
    params: {
      fields,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
    },
  });

  const response = await request.getPromise();
  return response.result;
};

export const trash = async ({ id }: { id: string }) => {
  const request = gapi.client.request({
    path: `https://www.googleapis.com/drive/v3/files/${id}`,
    method: 'PATCH',
    body: {
      trashed: true,
    },
    params: {
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
    },
  });

  const response = await request.getPromise();
  return response.result;
};

export const create = async ({
  name,
  mimeType,
  parents,
  shortcutDetails,
  properties,
  fields = fileFields,
}: {
  name: string;
  mimeType: DriveMimeType;
  parents: [string];
  shortcutDetails?: {
    targetId: string;
  };
  properties?: BaseFile['properties'];
  fields?: string;
}) => {
  const request = gapi.client.request({
    path: 'https://www.googleapis.com/drive/v3/files',
    method: 'POST',
    body: {
      name,
      mimeType,
      parents,
      ...(shortcutDetails && { shortcutDetails }),
      ...(properties !== undefined && { properties }),
    },
    params: {
      fields,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
    },
  });
  const response = await request.getPromise();
  return response.result;
};

export const rename = async ({ id, name, fields = fileFields }: { id: string; name: string; fields?: string }) => {
  const request = gapi.client.request({
    path: `https://www.googleapis.com/drive/v3/files/${id}`,
    method: 'PATCH',
    body: {
      name,
    },
    params: {
      fields,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
    },
  });

  const response = await request.getPromise();
  return response.result;
};

export const update = async ({
  id,
  name,
  mimeType,
  addParents,
  removeParents,
  trashed,
  properties,
  fields = fileFields,
}: {
  id: string;
  name?: string;
  mimeType?: DriveMimeType;
  addParents?: string;
  removeParents?: string;
  trashed?: boolean;
  properties?: BaseFile['properties'] | undefined;
  fields?: string;
}) => {
  // console.log('update', id, name, mimeType, addParents, removeParents, trashed, properties, fields);
  const request = gapi.client.request({
    path: `https://www.googleapis.com/drive/v3/files/${id}`,
    method: 'PATCH',
    params: {
      fields,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
      ...(addParents && { addParents }),
      ...(removeParents && { removeParents }),
    },
    body: {
      ...(name && { name }),
      ...(mimeType && { mimeType }),
      ...(properties && { properties }),
      ...(trashed !== undefined && { trashed }),
    },
  });

  const response = await request.getPromise();
  return response.result;
};

export const search = async ({
  q,
  fields = listFields,
  loggedIn = false,
  pageSize = 1000,
  trashed = false,
  driveId,
  nextPageToken,
}: {
  q: string;
  fields?: string;
  loggedIn?: boolean;
  pageSize?: number;
  trashed?: boolean;
  driveId?: string | undefined;
  nextPageToken?: string;
}): Promise<DriveFile[]> => {
  const token = gapi.client.getToken();
  if ((token && isFileScope(token.scope)) || !loggedIn) {
    const params = new URLSearchParams({
      q: `${q} and trashed = ${trashed}`,
      fields: `${fields},nextPageToken`,
      pageSize: pageSize.toString(),
      supportsAllDrives: 'true',
      includeItemsFromAllDrives: 'true',
      ...(nextPageToken && { pageToken: nextPageToken }),
      ...(driveId && {
        corpora: 'drive',
        driveId,
      }),
    })
      .toString()
      .replace(/\+/g, '%20')
      .replace(/'/g, '%27');

    const res = await fetch(
      `${baseURL}/drive/v3/files?${params}`,
      LEGACY
        ? token
          ? {
              headers: {
                token: JSON.stringify(pick(token, 'access_token', 'id_token')),
              },
            }
          : {
              headers: {
                path: window.location.pathname,
              },
            }
        : {
            credentials: 'include',
          }
    );
    if (!res.ok) {
      throw new Error(`Failed to fetch file: ${res.statusText}`);
    }
    const response = await res.json<{ result: { files: DriveFile[]; nextPageToken?: string } }>();
    const result = response.result;
    if (result.nextPageToken) {
      const files = await search({
        q,
        fields,
        pageSize,
        trashed,
        driveId,
        nextPageToken: result.nextPageToken,
      });
      return result.files.concat(files);
    }
    return result.files;
  }
  // note: needs 'name' field in 'file(fields)', otherwise empty array is always returned
  if (!fields.includes('name')) {
    throw new Error('fields must include "name"');
  }

  const { result } = await gapi.client
    .request({
      path: 'https://www.googleapis.com/drive/v3/files',
      params: {
        q: `${q} and trashed = ${trashed}`,
        fields: `${fields},nextPageToken`,
        pageSize,
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
        ...(nextPageToken && { pageToken: nextPageToken }),
        ...(driveId && {
          corpora: 'drive',
          driveId,
        }),
      },
    })
    .getPromise();

  if (result.nextPageToken) {
    const files = await search({
      q,
      fields,
      pageSize,
      trashed,
      driveId,
      nextPageToken: result.nextPageToken,
    });
    return result.files.concat(files);
  }
  return result.files;
};

export async function* listStream({
  query,
  fields = listFields,
  orderBy = 'folder,name_natural',
  pageSize = 1000,
  trashed = false,
  driveId,
  nextPageToken,
}: {
  query: string;
  fields?: string;
  orderBy?: 'folder,name_natural';
  pageSize?: number;
  trashed?: boolean;
  driveId?: string | undefined;
  nextPageToken?: string;
}): AsyncGenerator<DriveFile[], void, unknown> {
  const q = `${query} and trashed = ${trashed}`;
  log.info('listStream:', q);
  const { result } = await gapi.client.request({
    path: 'https://www.googleapis.com/drive/v3/files',
    params: {
      q,
      fields: `${fields},nextPageToken`,
      pageSize,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
      orderBy,
      ...(nextPageToken && { pageToken: nextPageToken }),
      ...(driveId && {
        corpora: 'drive',
        driveId,
      }),
    },
  });
  if (result.nextPageToken) {
    yield result.files;
    const filesStream = listStream({
      query,
      fields,
      orderBy,
      pageSize,
      trashed,
      driveId,
      nextPageToken: result.nextPageToken,
    });

    // It's possible for Drive to return a duplicate file in the next page as the first entry
    // This removes the duplicate
    for await (const files of filesStream) {
      if (files.at(0)?.id === result.files.at(-1)?.id) {
        yield files.slice(1);
      } else {
        yield files;
      }
    }
    return;
  }
  yield result.files;
}

export const list = async ({
  query,
  fields = listFields,
  orderBy = 'folder,name_natural',
  pageSize = 1000,
  trashed = false,
  driveId,
  nextPageToken,
}: {
  query: string;
  fields?: string;
  orderBy?: 'folder,name_natural';
  pageSize?: number;
  trashed?: boolean;
  driveId?: string | undefined;
  nextPageToken?: string;
}): Promise<DriveFile[]> => {
  const q = `${query} and trashed = ${trashed}`;
  log.info('list:', q);
  const { result } = await gapi.client.request({
    path: 'https://www.googleapis.com/drive/v3/files',
    params: {
      q,
      fields: `${fields},nextPageToken`,
      pageSize,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
      orderBy,
      ...(nextPageToken && { pageToken: nextPageToken }),
      ...(driveId && {
        corpora: 'drive',
        driveId,
      }),
    },
  });
  if (result.nextPageToken) {
    const files = await list({
      query,
      fields,
      orderBy,
      pageSize,
      trashed,
      driveId,
      nextPageToken: result.nextPageToken,
    });

    // It's possible for Drive to return a duplicate file in the next page as the first entry
    // This removes the duplicate
    if (files.at(0)?.id === result.files.at(-1)?.id) {
      return result.files.concat(files.slice(1));
    }
    return result.files.concat(files);
  }

  return result.files;
};

export const ancestor = async ({
  id,
  folderId,
  wikis,
  fields = 'id,name,parents',
}: {
  id: string;
  folderId: string;
  wikis: DriveFile[];
  fields?: string;
}): Promise<DriveFile> => {
  const file = await get({ id, fields });
  if (!file.parents) return file;
  if (wikis.some((wiki) => wiki.id === file.id)) return file;
  if (file.parents[0] === folderId) return file;
  return ancestor({ id: file.parents[0], folderId, wikis, fields });
};

export type ExportResult = UnionToIntersection<Awaited<ReturnType<typeof exportFile>>>;
export type DriveFileWithExport = DriveFile & { data: ExportResult };

export const exportFile = async ({
  id,
  fields,
  mimeType,
}: {
  id: string;
  mimeType: DriveMimeType;
  fields?: string;
}) => {
  const exportType = getExportMimeType(mimeType);
  if (!exportType) {
    if (unSupportedExportFormats.includes(mimeType)) return;
    if (DEV) log.error(`No exportType found for mimeType: ${mimeType}`);
    return;
  }

  if (mimeType === mimeTypes.docx) {
    const token = gapi.client.getToken();
    const url = `https://docs.google.com/feeds/download/documents/export/Export?id=${id}`;
    const response = await fetch(
      url,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          }
        : undefined
    );
    const html = await response.text();
    return { html };
  }

  if (mimeType === mimeTypes.ppt || mimeType === mimeTypes.pptx) {
    const url = `https://docs.google.com/feeds/download/presentations/Export?id=${id}&exportFormat=txt`;
    const token = gapi.client.getToken();
    const response = await fetch(
      url,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          }
        : undefined
    );
    const html = await response.text();
    return { html };
  }

  if (mimeType === mimeTypes.html) {
    const response = await gapi.client.request({
      path: `https://www.googleapis.com/drive/v3/files/${id}`,
      params: {
        ...(fields && { fields }),
        alt: 'media',
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
      },
    });
    return { html: decodeHTML(response.body) };
  }

  // PDF
  if (mimeType === mimeTypes.pdf) {
    // Start the pdf request async
    const request = gapi.client.request({
      path: `https://www.googleapis.com/drive/v3/files/${id}`,
      params: {
        ...(fields && { fields }),
        alt: 'media',
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
      },
    });

    // Start the worker download as soon as possible
    const [pdfjsLib, workerURL] = await Promise.all([
      import('pdfjs-dist'),
      // For now just use cdnjs, so we have the same environment in dev and prod
      // Once legacy is removed, we can switch back to using the local build
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${import.meta.env?.VITE_PDFJS_VERSION}/pdf.worker.min.mjs`,
    ]);
    pdfjsLib.GlobalWorkerOptions.workerSrc = workerURL;

    const data = (await request).body;

    // https://stackoverflow.com/a/75588333
    const arr = Uint8Array.from(data, (c) => c.charCodeAt(0)).buffer;
    const pdfDocument = await pdfjsLib.getDocument(arr).promise;
    const numPages = pdfDocument.numPages;
    const text = await Promise.all(
      Array.from({ length: numPages }, async (_, i) => {
        const page = await pdfDocument.getPage(i + 1);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map((item) => ('str' in item ? item.str : '')).join(' ');
        return pageText;
      })
    ).then((arr) => arr.join(' '));

    const blob = await pdfDocument.getData();
    const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
    const page = await pdfDocument.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    const firstPageHeight = page.getViewport({ scale: 802 / viewport.width }).height;
    const height = await Promise.all(
      Array.from({ length: numPages }, async (_, i) => {
        const page = await pdfDocument.getPage(i + 1);
        const { height } = page.getViewport({ scale: 805 / viewport.width });
        return height;
      })
    ).then((arr) => arr.reduce((acc, curr) => acc + curr, 0));

    // If there's are more than 30 pages, show 1 page
    // const totalHeight = numPages > 30 ? firstPageHeight : height + 2;
    const navBar = 58;
    const totalHeight = numPages > 30 ? firstPageHeight + navBar : height + 2 + navBar;

    return {
      height: totalHeight,
      url,
      text,
    };
  }

  if (mimeType === mimeTypes.text || mimeType === mimeTypes.markdown || mimeType === mimeTypes.markdownX) {
    const response = await gapi.client.request({
      path: `https://www.googleapis.com/drive/v3/files/${id}`,
      params: {
        ...(fields && { fields }),
        alt: 'media',
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
      },
    });
    if (mimeType === mimeTypes.markdown || mimeType === mimeTypes.markdownX) {
      const showdown = await import('showdown').then((o) => o.default);
      const converter = new showdown.Converter();
      const html = converter.makeHtml(response.body);

      return {
        html,
      };
    }

    return {
      html: sanitizeHTML(response.body),
    };
  }

  if (LEGACY && mimeType === mimeTypes.document) {
    const token = gapi.client.getToken();
    if (token && isFileScope(token.scope)) {
      const params = new URLSearchParams({
        ...(fields && { fields }),
        mimeType: exportType,
        supportsAllDrives: 'true',
        includeItemsFromAllDrives: 'true',
      })
        .toString()
        .replace(/\+/g, '%20')
        .replace(/'/g, '%27');
      const res = await fetch(
        `${baseURL}/drive/v3/files/${id}/export?${params}`,
        LEGACY
          ? {
              headers: {
                token: JSON.stringify(pick(token, 'access_token', 'id_token')),
              },
            }
          : {
              credentials: 'include',
            }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch file: ${res.statusText}`);
      }
      const text = await res.text();
      return { html: text };
    }
    const response = await gapi.client.request({
      path: `https://www.googleapis.com/drive/v3/files/${id}/export`,
      params: {
        ...(fields && { fields }),
        mimeType: exportType,
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
      },
    });
    return { html: response.body };
  }

  // Document for modern codebase
  // uses markdown to get checkboxes and code block language
  if (!isBun && MODERN && mimeType === mimeTypes.document && !gup('html')) {
    const response = await Promise.allSettled([
      gapi.client.request({
        path: `https://www.googleapis.com/drive/v3/files/${id}/export`,
        params: {
          ...(fields && { fields }),
          mimeType: exportType,
          supportsAllDrives: true,
          includeItemsFromAllDrives: true,
        },
      }),
      gapi.client.request({
        path: `https://www.googleapis.com/drive/v3/files/${id}/export`,
        params: {
          ...(fields && { fields }),
          mimeType: 'text/markdown',
          supportsAllDrives: true,
          includeItemsFromAllDrives: true,
        },
      }),
    ]);

    const [htmlResponse, markdownResponse] = response;
    if (htmlResponse.status === 'rejected') {
      throw htmlResponse.reason;
    }
    if (markdownResponse.status === 'rejected') {
      throw markdownResponse.reason;
    }

    let html = htmlResponse.value.body;
    const markdown = markdownResponse.value.body;
    if (DEV && gup('markdown')) log.info(markdown);

    // find every checkbox and the text that is next to it
    // find the corresponding list item in the the html
    // replace it with a macro {{checkbox}}
    // if all items in the ul are checkboxes, add a style to hide it
    if (/- \[(?:x|\s)\]/.test(markdown)) {
      // https://regex101.com/r/pJ9ZWL/1
      const checkboxes = markdown.match(/- \[(\s|x)\] .*(\n\s{6,}((?!(- \[(\s|x)\])).)*)*/g);
      const checked = checkboxes?.filter((o) => o.includes('- [x]'));
      const checkboxesText = checkboxes?.map((checkbox) =>
        checkbox
          .trim()
          .replace(/- \[x\] ~~|- \[ \]\s?/, '')
          .replace(/~~\s*$/, '')
          .replaceAll('\\-', '-')
          .replaceAll('\\>', '>')
          // replace [text](link) with text
          .replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
          // replace markdown apostrophe with regular apostrophe
          .replace(/\u2019/g, "'")
          .replace(/~~/g, '')
          .replace(/\s+/g, ' ')
      );

      html = html
        .replace(/<li.*?<\/li>/g, (li) => {
          const text = getTextFromHTML(li, false, false);
          const isCheckbox = checkboxesText?.includes(text);
          if (isCheckbox) {
            const index = checkboxesText?.indexOf(text);
            const value = index !== undefined ? checkboxes?.at(index) : undefined;
            const isChecked = value && checked?.includes(value);
            if (isChecked) return li.replace(/(<li[^>]*>)/, '$1{{checkbox}}{{checked}}');
            return li.replace(/(<li[^>]*>)/, '$1{{checkbox}}');
          }
          return li;
        })
        .replace(/<ul.*?<\/ul>/g, (ul) => {
          const li = ul.match(/<li.*?<\/li>/g);
          if (li?.every((li) => li.includes('{{checkbox}}'))) {
            const style = '<style>ul[data-style="checkbox"] li:before { content: none; }</style>';
            return ul.replace(/(<ul[^>]*)/, `${style}$1 data-style="checkbox"`);
          }
          return ul;
        });
    }

    // Code block language
    if (/```\w/.test(markdown)) {
      const languages =
        markdown
          .match(/```(\w+)/g)
          ?.map((o) => o.replace(/```/, '').replace(/^c$/, 'C/C++').replace('javascript', 'JavaScript')) || [];
      html = html.replace(/(&#60419;<\/span>)/g, (o) => {
        const language = languages.shift();
        if (language)
          return (
            o +
            `<span style="display:block;color:#747776;font-size:13px;font-weight:500;line-height:.8;padding-bottom:6px;">${language}</span>`
          );
        return o;
      });
    }

    return {
      html,
    };
  }

  // Sheets
  if (mimeType === mimeTypes.spreadsheet || mimeType === mimeTypes.xlsx) {
    const response =
      mimeType === mimeTypes.xlsx
        ? await gapi.client.request({
            path: `https://www.googleapis.com/drive/v3/files/${id}`,
            params: {
              ...(fields && { fields }),
              alt: 'media',
              supportsAllDrives: true,
              includeItemsFromAllDrives: true,
            },
          })
        : await gapi.client.request({
            path: `https://www.googleapis.com/drive/v3/files/${id}/export`,
            params: {
              ...(fields && { fields }),
              mimeType: exportType,
              supportsAllDrives: true,
              includeItemsFromAllDrives: true,
            },
          });

    const XLSX = await import('xlsx');
    const wb = XLSX.read(response.body, { type: 'binary' });
    const sheetName = wb.SheetNames[0];
    if (!sheetName) throw new Error('No sheet found');

    const pathSheetIndex = window.location.search.match(/s=(\d+)/)?.[1];

    const output = wb.SheetNames.map((sheetName, i) => {
      const sheet = wb.Sheets[sheetName];
      if (!sheet) throw new Error(`No sheet found for ${sheetName}`);
      let html = XLSX.utils
        .sheet_to_html(sheet)
        .replace(/(<tr>(<td[^>]*><\/td>)+<\/tr>)/g, '')
        .replace(/<html><head><meta charset="utf-8"\/><title>[^>]+<\/title><\/head><body>/g, '')
        .replace(/<\/body><\/html>/g, '');

      // Add hyperlinks
      for (const cell in sheet) {
        if (!sheet[cell]) continue;
        const cellV = getValue<string>(sheet[cell], 'v');
        const cellF = getValue<string>(sheet[cell], 'f');
        const match = cellF?.match(/HYPERLINK\("([^"]+)",\s*"([^"]+)"\)/);
        if (match) {
          const [_, url, text] = match;
          const a = `<a target="_blank" href="${url}">${text}</a>`;
          const cellValueRegex = new RegExp(`>${cellV}<`, 'g');
          html = html.replace(cellValueRegex, `>${a}<`);
        }
      }

      const showTable =
        (pathSheetIndex && Number(pathSheetIndex) === i) || (!pathSheetIndex && i === 0) ? '' : ' class="hide"';

      if (wb.SheetNames.length > 1 && i === wb.SheetNames.length - 1) {
        const s = dedent /* js */`
          function showSheet(e, i) {
            e.preventDefault();
            const sheets = document.querySelectorAll('[data-table]');
            sheets.forEach((sheet) => sheet.classList.add('hide'));
            sheets[i].classList.remove('hide');
            const links = document.querySelectorAll('[data-link]');
            links.forEach((link) => {
              link.style.fontWeight = '';
              link.style.color = 'inherit';
              link.style.background = '';
            });
            e.target.style.fontWeight = 'bold';
            e.target.style.color = '#0b57d0';
            e.target.style.background = '#e1e9f7';
            if(i > 0){
              history.replaceState({}, '', '?s=' + i);
            } else if(window.location.search.includes('s=')){
              history.replaceState({}, '', window.location.pathname);
            }
          }
        `;

        const activeSheet = window.location.search.match(/s=(\d+)/)?.[1] ?? '0';
        const getLinkstyle = (active = false) => `
          font-size: 14px;
          text-decoration: none;
          padding: 10px;
          color: inherit;
          ${
            active
              ? `
                background: #e1e9f7;
                font-weight: bold;
                color: #0b57d0;`
              : ''
          }
        `;

        const getLink = (sheetName: string, i: number) => {
          const linkStyle = getLinkstyle(activeSheet === i.toString());
          return /*html*/ `<a title="${sheetName}" data-link onclick="${s}; showSheet(event, ${i})" style="${linkStyle}" href="?s=${i}">${sheetName}</a>`;
        };

        const links = wb.SheetNames.map(getLink);
        return strip /*html*/`
          <div data-table${showTable}>${html}</div>
          <iframe data-iframe class="hide" style="width: 100%; height: calc(100vh - 500px); border: none;"></iframe>
          <div style="display:inline-flex; align-items: stretch; color: #444746; margin-top: .75rem;">
            <div style="display: flex; align-items: stretch;" data-react="SheetSettings"></div>
            <div style="display: flex; background: #f9fbfd; border-radius: 5px; overflow: hidden;">${links.join('')}</div>
          </div>
        `;
      }

      if (wb.SheetNames.length === 1) {
        return strip /* html */`
          <div data-table${showTable}>${html}</div>
          <iframe data-iframe class="hide" style="width: 100%; height: calc(100vh - 500px); border: none;"></iframe>
          <div style="display: flex; align-items: stretch; margin-top: .25rem;" data-react="SheetSettings"></div>
      `;
      }

      return strip /* html */`
        <div data-table${showTable}>${html}</div>
      `;
    });

    // Combine all HTML outputs into one string
    const html = output.join('');

    return {
      text: response.body.replace(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/gm, ' '),
      html,
    };
  }

  if (mimeType === mimeTypes.presentation) {
    return {
      html: '',
    };
  }

  // Everything else
  const response = await gapi.client.request({
    path: `https://www.googleapis.com/drive/v3/files/${id}/export`,
    params: {
      ...(fields && { fields }),
      mimeType: exportType,
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
    },
  });

  return {
    html: response.body,
  };
};
